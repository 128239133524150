import React, { useEffect } from 'react';
import Menu from '../../components/menu/Menu';
import CardProdutos from '../../components/card-produtos';
import CardWhy from '../../components/card-why';
import CalculadoraPeso from '../../components/calculadora';
import FormularioContato from '../../components/formulario-contatos';
import TabelaConversao from '../../components/conversao';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';

const listaProdutos = [
    { name: 'AÇO CARBONO', image: 'aco', text: 'Disponível em várias ligas e formatos, o Aço Carbono possui aplicações em diversas áreas, dentre elas as indústrias agrícolas, automobilísticas, de máquinas e equipamentos.'},
    { name: 'AÇO INOX', image: 'inox', text: 'Disponível em várias ligas e formatos, o Aço Inox é amplamente utilizado em diversas indústrias, dentre elas as indústrias químicas, petrolíferas e têxteis.'},
    { name: 'ALUMÍNIO', image: 'aluminio', text: 'Disponível em várias ligas e formatos, o Alumínio possui várias aplicações que vão desde a parte estrutural até a construção de peças para máquinas.'},
    { name: 'BRONZE', image: 'bronze', text: 'Disponível em várias ligas, buchas e tarugos, o Bronze é o metal mais utilizado na fabricação de engrenagens, coroas e mancais.'},
    { name: 'COBRE', image: 'cobre', text: 'Disponível em várias ligas e formatos, o Cobre é um dos materiais mais utilizados em todo o mundo, está presente em praticamente todos os painéis elétrios do mundo.'},
    { name: 'FERRO FUNDIDO', image: 'ff', text: 'Disponível em várias ligas e formatos, o Ferro Fundido é muito utilizado em diversas indústrias, na fabricação de bases de equipamentos, blocos de motores e etc.'},
    { name: 'LATÃO', image: 'latao', text: 'Disponível em várias ligas, e formatos, o Latão é um metal amplamente utilizado em decorações, bijuterias e parafusos.'},
    { name: 'NYLON', image: 'nylon', text: 'Disponível em várias ligas, e formatos, o Nylon é um plástico de engenharia amplamente utilizado na fabricação de engrenagens, rodas industriais e peças para móveis.'}
]

const listaWhy = [
    { icon: 'ClockClockwise', title: 'Entrega Rápida', text: 'Contamos com uma vasta opção de materiais e medidas em estoque, que possibilitam uma entrega imediata.'},
    { icon: 'Ruler', title: 'Peças na Medida Exata', text: 'Fornecemos o metal cortado na medida solicitada, reduzindo perdas e custos, além de facilitar o manuseio e transporte das peças.' },
    { icon: 'Certificate', title: 'Qualidade', text: 'Todos os nossos materiais são de procedência, possuindo os certificados de qualidade referentes a cada um deles.'}
]

function Home () {
        useEffect(() => {
            const hash = window.location.hash;
            if (hash){
                document.querySelector(`${hash}`).scrollIntoView();
            }
        }, [])
        return (
            <main className="body">
                <ToastContainer autoClose={3000} theme="colored"/>
                <header id="header" className="header-container">
                    <Menu typeRedirect={1}/>
                    <div className="header-txt-container">
                        <h1>Alta precisão começa com os melhores metais</h1>
                        <h2>confie na qualidade que impulsiona a sua produção.</h2>
                    </div>
                    <button className="header-button" onClick={() => { document.location.href="#contato-container" }}>Solicite um Orçamento</button>
                </header>
                <section id="produtos-container" className="section-container">
                    <div id="cabecalho-produtos-container">
                        <div id="ttl-produtos-container">
                            <h2>NOSSOS</h2>
                            <h1>PRODUTOS</h1>
                        </div>
                        <hr></hr>
                    </div>
                    <div className="produtos-card-container">
                        { listaProdutos.map((item) => {
                            return ( <CardProdutos key={item.name} name={ item.name } image={ item.image } text={ item.text } /> )
                        })}
                    </div>
                </section>
                <section id="why-container" className="section-container">
                    <div id="cabecalho-why-container">
                        <hr></hr>
                        <div id="ttl-why-container">
                            <h2>POR QUE A</h2>
                            <h1>BUTZKE?</h1>
                        </div>
                    </div>
                    <div className="motivos-box">
                        { listaWhy.map((item) => {
                            return (<CardWhy key={item.title} icon={item.icon} title={ item.title } text={ item.text }/>)
                        })}
                    </div>
                </section>
                <section id="peso-container" className="section-container">
                    <div id="cabecalho-peso-container">
                        <div id="ttl-peso-container">
                            <h2>CALCULADORA DE</h2>
                            <h1>PESO TEÓRICO</h1>
                        </div>
                        <hr></hr>
                    </div>
                    <div>
                        <CalculadoraPeso />
                    </div>
                </section>
                <section>
                    <div>
                        <TabelaConversao/>
                    </div>
                </section>
                <section id="contato-container" className="section-container">
                    <div id="cabecalho-contato-container">
                        <hr/>
                        <div id="ttl-contato-container">
                            <h2>ENTRE EM</h2>
                            <h1>CONTATO</h1>
                        </div>
                        <hr/>
                    </div>
                    <div className='contato-box'>
                        <div className='formulario-container'>
                            <FormularioContato/>
                        </div>
                        <div>
                            <div>
                                <h2>Localização</h2>
                                <hr/>
                                <h4>Rua Adolfo Augusto Alfredo Ziemann, 81</h4>
                                <h4>Czerniewicz, Jaraguá do Sul - SC</h4>
                                <h4>CEP: 89255-410</h4>
                            </div>
                            <div>
                                <h2>Entre em Contato</h2>
                                <hr/>
                                <h4>(47) 99163-9178</h4>
                                <h4>(47) 3055-0995</h4>
                                <h4>butzkemetais@gmail.com</h4>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }

export default Home;