import React, { Component } from 'react';
import './style.css';

class CardProdutos extends Component {
    render () {
        return (
            <div className='card' key={this.props.name}>
                <div className="image-container">
                    <img src={require(`../../assets/${this.props.image}.jpg`)} alt={this.props.type} />
                </div>
                <div className="grade">
                    <div>
                        <p id="nome-produto">{this.props.name}</p>
                        <p id="text-produto">{this.props.text || '' }</p>
                    </div>
                    <a className="ancora-produto" href={`/produto/${this.props.name}`}>SAIBA MAIS</a>
                </div>
            </div>
        )
    }
}

export default CardProdutos;