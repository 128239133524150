import { useState } from 'react';
import './style.css';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

import { IMaskInput } from "react-imask";

function FormularioContato () {

    function enviaEmail () {
        var nome = ''
        var email = ''
        var telefone = ''
         var cidade = ''
         var mensagem = ''
        Array.from(document.getElementsByClassName('inputText')).forEach(
            
            (input, index) => {
                switch(index) {
                    case 0:
                        nome = input.value
                        break
                    case 1:
                        email = input.value
                        break
                    case 2:
                        telefone = input.value
                        break
                    case 3:
                        cidade = input.value
                        break
                    case 4:
                        mensagem = input.value
                        break
                    default:
                        break     
                }
            }
            
        );

        const templateParams = {
            from_nome: nome,
            email,
            telefone,
            cidade,
            message: mensagem
        }
      
        if (nome && email && telefone && mensagem) {
            if (isEmailValid) {
                emailjs.send('service_ve9u8in', 'template_hklq8b7', templateParams, 'JrlWhzlaj75yfiGWJ').then((response) => {
                    toast.success('E-mail enviado com sucesso!')
                    clearCampos()
                }, (err) => {
                    toast.error('Ops, algo de errado aconteceu.')
                })
            } else {
                toast.error('Informe um endereço de e-mail válido.')
            }
        } else {
            toast.error('Preencha todos os campos obrigatórios.')
        }
    }

    function handleEmail (value) {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        setEmail(value)
        if (!regex.test(value.toLowerCase()) && value !== ''){
            setEmailValid(false);
            const element = document.getElementsByName('input-email')
            element[0].classList.add('input-error');
        } else {
            setEmailValid(true)
            const element = document.getElementsByName('input-email')
            element[0].classList.remove('input-error');
        }
    }

    function clearCampos(){
        Array.from(document.getElementsByClassName('inputText')).forEach(
            input => (input.value = '')
        );
        setNome('');
        setEmail('');
        setTelefone('');
        setCidade('');
        setMensagem('');
    }

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cidade, setCidade] = useState('');
    const [mensagem, setMensagem] = useState('');

    const [isEmailValid, setEmailValid] = useState(false);

    return (
        <div className='formulario'>
            <div>
                <p>Nome *</p>
                <input className="inputText" required onChange={(event) => {setNome(event.target.value)}} />
            </div>
            <div>
                <p>Email *</p>
                <input name="input-email" className="inputText" required onChange={(event) => { handleEmail(event.target.value)}} />
                { (!isEmailValid && email !== '') && <p className="email-invalid">Infome um endereço de e-mail válido.</p> }
            </div>
        
            <div>
                <p>Telefone *</p>
                <IMaskInput
                    mask="(00) 00000-0000"
                    className="inputText" 
                    required
                    onChange={(event) => {setTelefone(event.target.value)}}
                />
            </div>
            <div>
                <p>Cidade </p>
                <input className="inputText" onChange={(event) => {setCidade(event.target.value)}}/>
            </div>
            <div>
                <p>Mensagem *</p>
                <textarea className="inputText" onChange={(event) => {setMensagem(event.target.value)}}></textarea>
            </div>
            <div>
                <input id="btn" type='submit' value="Enviar Email" onClick={() => { enviaEmail() }}></input>
            </div>
        </div>
    )
}

export default FormularioContato;