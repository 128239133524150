import { React, useEffect, useState } from 'react'
import './style.css'
const listaMedidas = [
    {
        pol: "1/32",
        mm: "0.79"
    },
    {
        pol: "1/16",
        mm: "1.58"
    },
    {
        pol: "3/32",
        mm: "2.38"
    },
    {
        pol: "1/8",
        mm: "3.17"
    },
    {
        pol: "5/32",
        mm: "3.97"
    },
    {
        pol: "3/16",
        mm: "4.76"
    },
    {
        pol: "7/32",
        mm: "5.55"
    },
    {
        pol: "1/4",
        mm: "6.35"
    },
    {
        pol: "9/32",
        mm: "7.14"
    },
    {
        pol: "5/16",
        mm: "7.94"
    },
    {
        pol: "11/32",
        mm: "8.73"
    },
    {
        pol: "3/8",
        mm: "9.52"
    },
    {
        pol: "7/16",
        mm: "11.11"
    },
    {
        pol: "1/2",
        mm: "12.7"
    },
    {
        pol: "9/16",
        mm: "14.28"
    },
    {
        pol: "5/8",
        mm: "15.87"
    },
    {
        pol: "11/16",
        mm: "17.46"
    },
    {
        pol: "3/4",
        mm: "19.05"
    },
    {
        pol: "13/16",
        mm: "20.64"
    },
    {
        pol: "7/8",
        mm: "22.22"
    },
    {
        pol: "15/16",
        mm: "23.81"
    },
    {
        pol: "1",
        mm: "25.4"
    },
    {
        pol: "1.1/32",
        mm: "26.19"
    },
    {
        pol: "1.1/16",
        mm: "26.98"
    },
    {
        pol: "1.3/32",
        mm: "27.78"
    },
    {
        pol: "1.1/8",
        mm: "28.57"
    },
    {
        pol: "1.5/32",
        mm: "29.37"
    },
    {
        pol: "1.3/16",
        mm: "30.16"
    },
    {
        pol: "1.7/32",
        mm: "30.95"
    },
    {
        pol: "1.1/4",
        mm: "31.75"
    },
    {
        pol: "1.9/32",
        mm: "32.54"
    },
    {
        pol: "1.5/16",
        mm: "33.34"
    },
    {
        pol: "1.11/32",
        mm: "34.13"
    },
    {
        pol: "1.3/8",
        mm: "34.92"
    },
    {
        pol: "1.7/16",
        mm: "36.51"
    },
    {
        pol: "1.1/2",
        mm: "38.1"
    },
    {
        pol: "1.9/16",
        mm: "39.68"
    },
    {
        pol: "1.5/8",
        mm: "41.27"
    },
    {
        pol: "1.11/16",
        mm: "42.86"
    },
    {
        pol: "1.3/4",
        mm: "44.45"
    },
    {
        pol: "1.13/16",
        mm: "46.04"
    },
    {
        pol: "1.7/8",
        mm: "47.62"
    },
    {
        pol: "1.15/16",
        mm: "49.21"
    },
    {
        pol: "2",
        mm: "50.8"
    },
    {
        pol: "2.1/32",
        mm: "51.59"
    },
    {
        pol: "2.1/16",
        mm: "52.38"
    },
    {
        pol: "2.3/32",
        mm: "53.18"
    },
    {
        pol: "2.1/8",
        mm: "53.97"
    },
    {
        pol: "2.5/32",
        mm: "54.77"
    },
    {
        pol: "2.3/16",
        mm: "55.56"
    },
    {
        pol: "2.7/32",
        mm: "56.35"
    },
    {
        pol: "2.1/4",
        mm: "57.15"
    },
    {
        pol: "2.9/32",
        mm: "57.94"
    },
    {
        pol: "2.5/16",
        mm: "58.74"
    },
    {
        pol: "2.11/32",
        mm: "59.53"
    },
    {
        pol: "2.3/8",
        mm: "60.32"
    },
    {
        pol: "2.7/16",
        mm: "61.91"
    },
    {
        pol: "2.1/2",
        mm: "63.5"
    },
    {
        pol: "2.9/16",
        mm: "65.08"
    },
    {
        pol: "2.5/8",
        mm: "66.67"
    },
    {
        pol: "2.11/16",
        mm: "68.26"
    },
    {
        pol: "2.3/4",
        mm: "69.85"
    },
    {
        pol: "2.13/16",
        mm: "71.44"
    },
    {
        pol: "2.7/8",
        mm: "73.02"
    },
    {
        pol: "2.15/16",
        mm: "74.61"
    },
    {
        pol: "3",
        mm: "76.2"
    },
    {
        pol: "3.1/32",
        mm: "76.99"
    },
    {
        pol: "3.1/16",
        mm: "77.78"
    },
    {
        pol: "3.3/32",
        mm: "78.58"
    },
    {
        pol: "3.1/8",
        mm: "79.37"
    },
    {
        pol: "3.5/32",
        mm: "80.17"
    },
    {
        pol: "3.3/16",
        mm: "80.96"
    },
    {
        pol: "3.7/32",
        mm: "81.75"
    },
    {
        pol: "3.1/4",
        mm: "82.55"
    },
    {
        pol: "3.9/32",
        mm: "83.34"
    },
    {
        pol: "3.5/16",
        mm: "84.14"
    },
    {
        pol: "3.11/32",
        mm: "84.93"
    },
    {
        pol: "3.3/8",
        mm: "85.72"
    },
    {
        pol: "3.7/16",
        mm: "87.31"
    },
    {
        pol: "3.1/2",
        mm: "88.9"
    },
    {
        pol: "3.9/16",
        mm: "90.48"
    },
    {
        pol: "3.5/8",
        mm: "92.07"
    },
    {
        pol: "3.11/16",
        mm: "93.66"
    },
    {
        pol: "3.3/4",
        mm: "95.25"
    },
    {
        pol: "3.13/16",
        mm: "96.84"
    },
    {
        pol: "3.7/8",
        mm: "98.42"
    },
    {
        pol: "3.15/16",
        mm: "100.01"
    },
    {
        pol: "4",
        mm: "101.6"
    },
    {
        pol: "4.1/32",
        mm: "102.39"
    },
    {
        pol: "4.1/16",
        mm: "103.18"
    },
    {
        pol: "4.3/32",
        mm: "103.98"
    },
    {
        pol: "4.1/8",
        mm: "104.77"
    },
    {
        pol: "4.5/32",
        mm: "105.57"
    },
    {
        pol: "4.3/16",
        mm: "106.36"
    },
    {
        pol: "4.7/32",
        mm: "107.15"
    },
    {
        pol: "4.1/4",
        mm: "107.95"
    },
    {
        pol: "4.9/32",
        mm: "108.74"
    },
    {
        pol: "4.5/16",
        mm: "109.54"
    },
    {
        pol: "4.11/32",
        mm: "110.33"
    },
    {
        pol: "4.3/8",
        mm: "111.12"
    },
    {
        pol: "4.7/16",
        mm: "112.71"
    },
    {
        pol: "4.1/2",
        mm: "114.3"
    },
    {
        pol: "4.9/16",
        mm: "115.88"
    },
    {
        pol: "4.5/8",
        mm: "117.47"
    },
    {
        pol: "4.11/16",
        mm: "119.06"
    },
    {
        pol: "4.3/4",
        mm: "120.65"
    },
    {
        pol: "4.13/16",
        mm: "122.24"
    },
    {
        pol: "4.7/8",
        mm: "123.82"
    },
    {
        pol: "4.15/16",
        mm: "125.41"
    },
    {
        pol: "5",
        mm: "127"
    },
    {
        pol: "5.1/32",
        mm: "127.79"
    },
    {
        pol: "5.1/16",
        mm: "128.58"
    },
    {
        pol: "5.3/32",
        mm: "129.38"
    },
    {
        pol: "5.1/8",
        mm: "130.17"
    },
    {
        pol: "5.5/32",
        mm: "130.97"
    },
    {
        pol: "5.3/16",
        mm: "131.76"
    },
    {
        pol: "5.7/32",
        mm: "132.55"
    },
    {
        pol: "5.1/4",
        mm: "133.35"
    },
    {
        pol: "5.9/32",
        mm: "134.14"
    },
    {
        pol: "5.5/16",
        mm: "134.94"
    },
    {
        pol: "5.11/32",
        mm: "135.73"
    },
    {
        pol: "5.3/8",
        mm: "136.52"
    },
    {
        pol: "5.7/16",
        mm: "138.11"
    },
    {
        pol: "5.1/2",
        mm: "139.7"
    },
    {
        pol: "5.9/16",
        mm: "141.28"
    },
    {
        pol: "5.5/8",
        mm: "142.87"
    },
    {
        pol: "5.11/16",
        mm: "144.46"
    },
    {
        pol: "5.3/4",
        mm: "146.05"
    },
    {
        pol: "5.13/16",
        mm: "147.64"
    },
    {
        pol: "5.7/8",
        mm: "149.22"
    },
    {
        pol: "5.15/16",
        mm: "150.81"
    },
    {
        pol: "6",
        mm: "152.4"
    },
    {
        pol: "6.1/32",
        mm: "153.19"
    },
    {
        pol: "6.1/16",
        mm: "153.98"
    },
    {
        pol: "6.3/32",
        mm: "154.78"
    },
    {
        pol: "6.1/8",
        mm: "155.57"
    },
    {
        pol: "6.5/32",
        mm: "156.37"
    },
    {
        pol: "6.3/16",
        mm: "157.16"
    },
    {
        pol: "6.7/32",
        mm: "157.95"
    },
    {
        pol: "6.1/4",
        mm: "158.75"
    },
    {
        pol: "6.9/32",
        mm: "159.54"
    },
    {
        pol: "6.5/16",
        mm: "160.34"
    },
    {
        pol: "6.11/32",
        mm: "161.13"
    },
    {
        pol: "6.3/8",
        mm: "161.92"
    },
    {
        pol: "6.7/16",
        mm: "163.51"
    },
    {
        pol: "6.1/2",
        mm: "165.1"
    },
    {
        pol: "6.9/16",
        mm: "166.68"
    },
    {
        pol: "6.5/8",
        mm: "168.27"
    },
    {
        pol: "6.11/16",
        mm: "169.86"
    },
    {
        pol: "6.3/4",
        mm: "171.45"
    },
    {
        pol: "6.13/16",
        mm: "173.04"
    },
    {
        pol: "6.7/8",
        mm: "174.62"
    },
    {
        pol: "6.15/16",
        mm: "176.21"
    },
    {
        pol: "7",
        mm: "177.8"
    },
    {
        pol: "7.1/32",
        mm: "178.59"
    },
    {
        pol: "7.1/16",
        mm: "179.38"
    },
    {
        pol: "7.3/32",
        mm: "180.18"
    },
    {
        pol: "7.1/8",
        mm: "180.97"
    },
    {
        pol: "7.5/32",
        mm: "181.77"
    },
    {
        pol: "7.3/16",
        mm: "182.56"
    },
    {
        pol: "7.7/32",
        mm: "183.35"
    },
    {
        pol: "7.1/4",
        mm: "184.15"
    },
    {
        pol: "7.9/32",
        mm: "184.94"
    },
    {
        pol: "7.5/16",
        mm: "185.74"
    },
    {
        pol: "7.11/32",
        mm: "186.53"
    },
    {
        pol: "7.3/8",
        mm: "187.32"
    },
    {
        pol: "7.7/16",
        mm: "188.91"
    },
    {
        pol: "7.1/2",
        mm: "190.5"
    },
    {
        pol: "7.9/16",
        mm: "192.08"
    },
    {
        pol: "7.5/8",
        mm: "193.67"
    },
    {
        pol: "7.11/16",
        mm: "195.26"
    },
    {
        pol: "7.3/4",
        mm: "196.85"
    },
    {
        pol: "7.13/16",
        mm: "198.44"
    },
    {
        pol: "7.7/8",
        mm: "200.02"
    },
    {
        pol: "7.15/16",
        mm: "201.61"
    },
    {
        pol: "8",
        mm: "203.2"
    },
    {
        pol: "8.1/32",
        mm: "203.99"
    },
    {
        pol: "8.1/16",
        mm: "204.78"
    },
    {
        pol: "8.3/32",
        mm: "205.58"
    },
    {
        pol: "8.1/8",
        mm: "206.37"
    },
    {
        pol: "8.5/32",
        mm: "207.17"
    },
    {
        pol: "8.3/16",
        mm: "207.96"
    },
    {
        pol: "8.7/32",
        mm: "208.75"
    },
    {
        pol: "8.1/4",
        mm: "209.55"
    },
    {
        pol: "8.9/32",
        mm: "210.34"
    },
    {
        pol: "8.5/16",
        mm: "211.14"
    },
    {
        pol: "8.11/32",
        mm: "211.93"
    },
    {
        pol: "8.3/8",
        mm: "212.72"
    },
    {
        pol: "8.7/16",
        mm: "214.31"
    },
    {
        pol: "8.1/2",
        mm: "215.9"
    },
    {
        pol: "8.9/16",
        mm: "217.48"
    },
    {
        pol: "8.5/8",
        mm: "219.07"
    },
    {
        pol: "8.11/16",
        mm: "220.66"
    },
    {
        pol: "8.3/4",
        mm: "222.25"
    },
    {
        pol: "8.13/16",
        mm: "223.84"
    },
    {
        pol: "8.7/8",
        mm: "225.42"
    },
    {
        pol: "8.15/16",
        mm: "227.01"
    },
    {
        pol: "9",
        mm: "228.6"
    },
    {
        pol: "9.1/32",
        mm: "229.39"
    },
    {
        pol: "9.1/16",
        mm: "230.18"
    },
    {
        pol: "9.3/32",
        mm: "230.98"
    },
    {
        pol: "9.1/8",
        mm: "231.77"
    },
    {
        pol: "9.5/32",
        mm: "232.57"
    },
    {
        pol: "9.3/16",
        mm: "233.36"
    },
    {
        pol: "9.7/32",
        mm: "234.15"
    },
    {
        pol: "9.1/4",
        mm: "234.95"
    },
    {
        pol: "9.9/32",
        mm: "235.74"
    },
    {
        pol: "9.5/16",
        mm: "236.54"
    },
    {
        pol: "9.11/32",
        mm: "237.33"
    },
    {
        pol: "9.3/8",
        mm: "238.12"
    },
    {
        pol: "9.7/16",
        mm: "239.71"
    },
    {
        pol: "9.1/2",
        mm: "241.3"
    },
    {
        pol: "9.9/16",
        mm: "242.88"
    },
    {
        pol: "9.5/8",
        mm: "244.47"
    },
    {
        pol: "9.11/16",
        mm: "246.06"
    },
    {
        pol: "9.3/4",
        mm: "247.65"
    },
    {
        pol: "9.13/16",
        mm: "249.24"
    },
    {
        pol: "9.7/8",
        mm: "250.82"
    },
    {
        pol: "9.15/16",
        mm: "252.41"
    },
    {
        pol: "10",
        mm: "254"
    },
    {
        pol: "10.1/32",
        mm: "254.79"
    },
    {
        pol: "10.1/16",
        mm: "255.58"
    },
    {
        pol: "10.3/32",
        mm: "256.38"
    },
    {
        pol: "10.1/8",
        mm: "257.17"
    },
    {
        pol: "10.5/32",
        mm: "257.97"
    },
    {
        pol: "10.3/16",
        mm: "258.76"
    },
    {
        pol: "10.7/32",
        mm: "259.55"
    },
    {
        pol: "10.1/4",
        mm: "260.35"
    },
    {
        pol: "10.9/32",
        mm: "261.14"
    },
    {
        pol: "10.5/16",
        mm: "261.94"
    },
    {
        pol: "10.11/32",
        mm: "262.73"
    },
    {
        pol: "10.3/8",
        mm: "263.52"
    },
    {
        pol: "10.7/16",
        mm: "265.11"
    },
    {
        pol: "10.1/2",
        mm: "266.7"
    },
    {
        pol: "10.9/16",
        mm: "268.28"
    },
    {
        pol: "10.5/8",
        mm: "269.87"
    },
    {
        pol: "10.11/16",
        mm: "271.46"
    },
    {
        pol: "10.3/4",
        mm: "273.05"
    },
    {
        pol: "10.13/16",
        mm: "274.64"
    },
    {
        pol: "10.7/8",
        mm: "276.22"
    },
    {
        pol: "10.15/16",
        mm: "277.81"
    },
    {
        pol: "11",
        mm: "279.4"
    },
    {
        pol: "11.1/32",
        mm: "280.19"
    },
    {
        pol: "11.1/16",
        mm: "280.98"
    },
    {
        pol: "11.3/32",
        mm: "281.78"
    },
    {
        pol: "11.1/8",
        mm: "282.57"
    },
    {
        pol: "11.5/32",
        mm: "283.37"
    },
    {
        pol: "11.3/16",
        mm: "284.16"
    },
    {
        pol: "11.7/32",
        mm: "284.95"
    },
    {
        pol: "11.1/4",
        mm: "285.75"
    },
    {
        pol: "11.9/32",
        mm: "286.54"
    },
    {
        pol: "11.5/16",
        mm: "287.34"
    },
    {
        pol: "11.11/32",
        mm: "288.13"
    },
    {
        pol: "11.3/8",
        mm: "288.92"
    },
    {
        pol: "11.7/16",
        mm: "290.51"
    },
    {
        pol: "11.1/2",
        mm: "292.1"
    },
    {
        pol: "11.9/16",
        mm: "293.68"
    },
    {
        pol: "11.5/8",
        mm: "295.27"
    },
    {
        pol: "11.11/16",
        mm: "296.86"
    },
    {
        pol: "11.3/4",
        mm: "298.45"
    },
    {
        pol: "11.13/16",
        mm: "300.04"
    },
    {
        pol: "11.7/8",
        mm: "301.62"
    },
    {
        pol: "11.15/16",
        mm: "303.21"
    },
    {
        pol: "12",
        mm: "304.8"
    },
    {
        pol: "12.1/32",
        mm: "305.59"
    },
    {
        pol: "12.1/16",
        mm: "306.38"
    },
    {
        pol: "12.3/32",
        mm: "307.18"
    },
    {
        pol: "12.1/8",
        mm: "307.97"
    },
    {
        pol: "12.5/32",
        mm: "308.77"
    },
    {
        pol: "12.3/16",
        mm: "309.56"
    },
    {
        pol: "12.7/32",
        mm: "310.35"
    },
    {
        pol: "12.1/4",
        mm: "311.15"
    },
    {
        pol: "12.9/32",
        mm: "311.94"
    },
    {
        pol: "12.5/16",
        mm: "312.74"
    },
    {
        pol: "12.11/32",
        mm: "313.53"
    },
    {
        pol: "12.3/8",
        mm: "314.32"
    },
    {
        pol: "12.7/16",
        mm: "315.91"
    },
    {
        pol: "12.1/2",
        mm: "317.5"
    },
    {
        pol: "12.9/16",
        mm: "319.08"
    },
    {
        pol: "12.5/8",
        mm: "320.67"
    },
    {
        pol: "12.11/16",
        mm: "322.26"
    },
    {
        pol: "12.3/4",
        mm: "323.85"
    },
    {
        pol: "12.13/16",
        mm: "325.44"
    },
    {
        pol: "12.7/8",
        mm: "327.02"
    },
    {
        pol: "12.15/16",
        mm: "328.61"
    },
    {
        pol: "13",
        mm: "330.2"
    },
    {
        pol: "13.1/32",
        mm: "330.99"
    },
    {
        pol: "13.1/16",
        mm: "331.78"
    },
    {
        pol: "13.3/32",
        mm: "332.58"
    },
    {
        pol: "13.1/8",
        mm: "333.37"
    },
    {
        pol: "13.5/32",
        mm: "334.17"
    },
    {
        pol: "13.3/16",
        mm: "334.96"
    },
    {
        pol: "13.7/32",
        mm: "335.75"
    },
    {
        pol: "13.1/4",
        mm: "336.55"
    },
    {
        pol: "13.9/32",
        mm: "337.34"
    },
    {
        pol: "13.5/16",
        mm: "338.14"
    },
    {
        pol: "13.11/32",
        mm: "338.93"
    },
    {
        pol: "13.3/8",
        mm: "339.72"
    },
    {
        pol: "13.7/16",
        mm: "341.31"
    },
    {
        pol: "13.1/2",
        mm: "342.9"
    },
    {
        pol: "13.9/16",
        mm: "344.48"
    },
    {
        pol: "13.5/8",
        mm: "346.07"
    },
    {
        pol: "13.11/16",
        mm: "347.66"
    },
    {
        pol: "13.3/4",
        mm: "349.25"
    },
    {
        pol: "13.13/16",
        mm: "350.84"
    },
    {
        pol: "13.7/8",
        mm: "352.42"
    },
    {
        pol: "13.15/16",
        mm: "354.01"
    },
    {
        pol: "14",
        mm: "355.6"
    },
    {
        pol: "14.1/32",
        mm: "356.39"
    },
    {
        pol: "14.1/16",
        mm: "357.18"
    },
    {
        pol: "14.3/32",
        mm: "357.98"
    },
    {
        pol: "14.1/8",
        mm: "358.77"
    },
    {
        pol: "14.5/32",
        mm: "359.57"
    },
    {
        pol: "14.3/16",
        mm: "360.36"
    },
    {
        pol: "14.7/32",
        mm: "361.15"
    },
    {
        pol: "14.1/4",
        mm: "361.95"
    },
    {
        pol: "14.9/32",
        mm: "362.74"
    },
    {
        pol: "14.5/16",
        mm: "363.54"
    },
    {
        pol: "14.11/32",
        mm: "364.33"
    },
    {
        pol: "14.3/8",
        mm: "365.12"
    },
    {
        pol: "14.7/16",
        mm: "366.71"
    },
    {
        pol: "14.1/2",
        mm: "368.3"
    },
    {
        pol: "14.9/16",
        mm: "369.88"
    },
    {
        pol: "14.5/8",
        mm: "371.47"
    },
    {
        pol: "14.11/16",
        mm: "373.06"
    },
    {
        pol: "14.3/4",
        mm: "374.65"
    },
    {
        pol: "14.13/16",
        mm: "376.24"
    },
    {
        pol: "14.7/8",
        mm: "377.82"
    },
    {
        pol: "14.15/16",
        mm: "379.41"
    },
    {
        pol: "15",
        mm: "381"
    },
    {
        pol: "15.1/32",
        mm: "381.79"
    },
    {
        pol: "15.1/16",
        mm: "382.58"
    },
    {
        pol: "15.3/32",
        mm: "383.38"
    },
    {
        pol: "15.1/8",
        mm: "384.17"
    },
    {
        pol: "15.5/32",
        mm: "384.97"
    },
    {
        pol: "15.3/16",
        mm: "385.76"
    },
    {
        pol: "15.7/32",
        mm: "386.55"
    },
    {
        pol: "15.1/4",
        mm: "387.35"
    },
    {
        pol: "15.9/32",
        mm: "388.14"
    },
    {
        pol: "15.5/16",
        mm: "388.94"
    },
    {
        pol: "15.11/32",
        mm: "389.73"
    },
    {
        pol: "15.3/8",
        mm: "390.52"
    },
    {
        pol: "15.7/16",
        mm: "392.11"
    },
    {
        pol: "15.1/2",
        mm: "393.7"
    },
    {
        pol: "15.9/16",
        mm: "395.28"
    },
    {
        pol: "15.5/8",
        mm: "396.87"
    },
    {
        pol: "15.11/16",
        mm: "398.46"
    },
    {
        pol: "15.3/4",
        mm: "400.05"
    },
    {
        pol: "15.13/16",
        mm: "401.64"
    },
    {
        pol: "15.7/8",
        mm: "403.22"
    },
    {
        pol: "15.15/16",
        mm: "404.81"
    },
    {
        pol: "16",
        mm: "406.4"
    },
    {
        pol: "16.1/32",
        mm: "407.19"
    },
    {
        pol: "16.1/16",
        mm: "407.98"
    },
    {
        pol: "16.3/32",
        mm: "408.78"
    },
    {
        pol: "16.1/8",
        mm: "409.57"
    },
    {
        pol: "16.5/32",
        mm: "410.37"
    },
    {
        pol: "16.3/16",
        mm: "411.16"
    },
    {
        pol: "16.7/32",
        mm: "411.95"
    },
    {
        pol: "16.1/4",
        mm: "412.75"
    },
    {
        pol: "16.9/32",
        mm: "413.54"
    },
    {
        pol: "16.5/16",
        mm: "414.34"
    },
    {
        pol: "16.11/32",
        mm: "415.13"
    },
    {
        pol: "16.3/8",
        mm: "415.92"
    },
    {
        pol: "16.7/16",
        mm: "417.51"
    },
    {
        pol: "16.1/2",
        mm: "419.1"
    },
    {
        pol: "16.9/16",
        mm: "420.68"
    },
    {
        pol: "16.5/8",
        mm: "422.27"
    },
    {
        pol: "16.11/16",
        mm: "423.86"
    },
    {
        pol: "16.3/4",
        mm: "425.45"
    },
    {
        pol: "16.13/16",
        mm: "427.04"
    },
    {
        pol: "16.7/8",
        mm: "428.62"
    },
    {
        pol: "16.15/16",
        mm: "430.21"
    },
    {
        pol: "17",
        mm: "431.8"
    },
    {
        pol: "17.1/32",
        mm: "432.59"
    },
    {
        pol: "17.1/16",
        mm: "433.38"
    },
    {
        pol: "17.3/32",
        mm: "434.18"
    },
    {
        pol: "17.1/8",
        mm: "434.97"
    },
    {
        pol: "17.5/32",
        mm: "435.77"
    },
    {
        pol: "17.3/16",
        mm: "436.56"
    },
    {
        pol: "17.7/32",
        mm: "437.35"
    },
    {
        pol: "17.1/4",
        mm: "438.15"
    },
    {
        pol: "17.9/32",
        mm: "438.94"
    },
    {
        pol: "17.5/16",
        mm: "439.74"
    },
    {
        pol: "17.11/32",
        mm: "440.53"
    },
    {
        pol: "17.3/8",
        mm: "441.32"
    },
    {
        pol: "17.7/16",
        mm: "442.91"
    },
    {
        pol: "17.1/2",
        mm: "444.5"
    },
    {
        pol: "17.9/16",
        mm: "446.08"
    },
    {
        pol: "17.5/8",
        mm: "447.67"
    },
    {
        pol: "17.11/16",
        mm: "449.26"
    },
    {
        pol: "17.3/4",
        mm: "450.85"
    },
    {
        pol: "17.13/16",
        mm: "452.44"
    },
    {
        pol: "17.7/8",
        mm: "454.02"
    },
    {
        pol: "17.15/16",
        mm: "455.61"
    },
    {
        pol: "18",
        mm: "457.2"
    },
    {
        pol: "18.1/32",
        mm: "457.99"
    },
    {
        pol: "18.1/16",
        mm: "458.78"
    },
    {
        pol: "18.3/32",
        mm: "459.58"
    },
    {
        pol: "18.1/8",
        mm: "460.37"
    },
    {
        pol: "18.5/32",
        mm: "461.17"
    },
    {
        pol: "18.3/16",
        mm: "461.96"
    },
    {
        pol: "18.7/32",
        mm: "462.75"
    },
    {
        pol: "18.1/4",
        mm: "463.55"
    },
    {
        pol: "18.9/32",
        mm: "464.34"
    },
    {
        pol: "18.5/16",
        mm: "465.14"
    },
    {
        pol: "18.11/32",
        mm: "465.93"
    },
    {
        pol: "18.3/8",
        mm: "466.72"
    },
    {
        pol: "18.7/16",
        mm: "468.31"
    },
    {
        pol: "18.1/2",
        mm: "469.9"
    },
    {
        pol: "18.9/16",
        mm: "471.48"
    },
    {
        pol: "18.5/8",
        mm: "473.07"
    },
    {
        pol: "18.11/16",
        mm: "474.66"
    },
    {
        pol: "18.3/4",
        mm: "476.25"
    },
    {
        pol: "18.13/16",
        mm: "477.84"
    },
    {
        pol: "18.7/8",
        mm: "479.42"
    },
    {
        pol: "18.15/16",
        mm: "481.01"
    },
    {
        pol: "19",
        mm: "482.6"
    },
    {
        pol: "19.1/32",
        mm: "483.39"
    },
    {
        pol: "19.1/16",
        mm: "484.18"
    },
    {
        pol: "19.3/32",
        mm: "484.98"
    },
    {
        pol: "19.1/8",
        mm: "485.77"
    },
    {
        pol: "19.5/32",
        mm: "486.57"
    },
    {
        pol: "19.3/16",
        mm: "487.36"
    },
    {
        pol: "19.7/32",
        mm: "488.15"
    },
    {
        pol: "19.1/4",
        mm: "488.95"
    },
    {
        pol: "19.9/32",
        mm: "489.74"
    },
    {
        pol: "19.5/16",
        mm: "490.54"
    },
    {
        pol: "19.11/32",
        mm: "491.33"
    },
    {
        pol: "19.3/8",
        mm: "492.12"
    },
    {
        pol: "19.7/16",
        mm: "493.71"
    },
    {
        pol: "19.1/2",
        mm: "495.3"
    },
    {
        pol: "19.9/16",
        mm: "496.88"
    },
    {
        pol: "19.5/8",
        mm: "498.47"
    },
    {
        pol: "19.11/16",
        mm: "500.06"
    },
    {
        pol: "19.3/4",
        mm: "501.65"
    },
    {
        pol: "19.13/16",
        mm: "503.24"
    },
    {
        pol: "19.7/8",
        mm: "504.82"
    },
    {
        pol: "19.15/16",
        mm: "506.41"
    },
    {
        pol: "20",
        mm: "508"
    },
    {
        pol: "20.1/32",
        mm: "508.79"
    },
    {
        pol: "20.1/16",
        mm: "509.58"
    },
    {
        pol: "20.3/32",
        mm: "510.38"
    },
    {
        pol: "20.1/8",
        mm: "511.17"
    },
    {
        pol: "20.5/32",
        mm: "511.97"
    },
    {
        pol: "20.3/16",
        mm: "512.76"
    },
    {
        pol: "20.7/32",
        mm: "513.55"
    },
    {
        pol: "20.1/4",
        mm: "514.35"
    },
    {
        pol: "20.9/32",
        mm: "515.14"
    },
    {
        pol: "20.5/16",
        mm: "515.94"
    },
    {
        pol: "20.11/32",
        mm: "516.73"
    },
    {
        pol: "20.3/8",
        mm: "517.52"
    },
    {
        pol: "20.7/16",
        mm: "519.11"
    },
    {
        pol: "20.1/2",
        mm: "520.7"
    },
    {
        pol: "20.9/16",
        mm: "522.28"
    },
    {
        pol: "20.5/8",
        mm: "523.87"
    },
    {
        pol: "20.11/16",
        mm: "525.46"
    },
    {
        pol: "20.3/4",
        mm: "527.05"
    },
    {
        pol: "20.13/16",
        mm: "528.64"
    },
    {
        pol: "20.7/8",
        mm: "530.22"
    },
    {
        pol: "20.15/16",
        mm: "531.81"
    },
    {
        pol: "21",
        mm: "533.4"
    },
    {
        pol: "21.1/32",
        mm: "534.19"
    },
    {
        pol: "21.1/16",
        mm: "534.98"
    },
    {
        pol: "21.3/32",
        mm: "535.78"
    },
    {
        pol: "21.1/8",
        mm: "536.57"
    },
    {
        pol: "21.5/32",
        mm: "537.37"
    },
    {
        pol: "21.3/16",
        mm: "538.16"
    },
    {
        pol: "21.7/32",
        mm: "538.95"
    },
    {
        pol: "21.1/4",
        mm: "539.75"
    },
    {
        pol: "21.9/32",
        mm: "540.54"
    },
    {
        pol: "21.5/16",
        mm: "541.34"
    },
    {
        pol: "21.11/32",
        mm: "542.13"
    },
    {
        pol: "21.3/8",
        mm: "542.92"
    },
    {
        pol: "21.7/16",
        mm: "544.51"
    },
    {
        pol: "21.1/2",
        mm: "546.1"
    },
    {
        pol: "21.9/16",
        mm: "547.68"
    },
    {
        pol: "21.5/8",
        mm: "549.27"
    },
    {
        pol: "21.11/16",
        mm: "550.86"
    },
    {
        pol: "21.3/4",
        mm: "552.45"
    },
    {
        pol: "21.13/16",
        mm: "554.04"
    },
    {
        pol: "21.7/8",
        mm: "555.62"
    },
    {
        pol: "21.15/16",
        mm: "557.21"
    },
    {
        pol: "22",
        mm: "558.8"
    },
    {
        pol: "22.1/32",
        mm: "559.59"
    },
    {
        pol: "22.1/16",
        mm: "560.38"
    },
    {
        pol: "22.3/32",
        mm: "561.18"
    },
    {
        pol: "22.1/8",
        mm: "561.97"
    },
    {
        pol: "22.5/32",
        mm: "562.77"
    },
    {
        pol: "22.3/16",
        mm: "563.56"
    },
    {
        pol: "22.7/32",
        mm: "564.35"
    },
    {
        pol: "22.1/4",
        mm: "565.15"
    },
    {
        pol: "22.9/32",
        mm: "565.94"
    },
    {
        pol: "22.5/16",
        mm: "566.74"
    },
    {
        pol: "22.11/32",
        mm: "567.53"
    },
    {
        pol: "22.3/8",
        mm: "568.32"
    },
    {
        pol: "22.7/16",
        mm: "569.91"
    },
    {
        pol: "22.1/2",
        mm: "571.5"
    },
    {
        pol: "22.9/16",
        mm: "573.08"
    },
    {
        pol: "22.5/8",
        mm: "574.67"
    },
    {
        pol: "22.11/16",
        mm: "576.26"
    },
    {
        pol: "22.3/4",
        mm: "577.85"
    },
    {
        pol: "22.13/16",
        mm: "579.44"
    },
    {
        pol: "22.7/8",
        mm: "581.02"
    },
    {
        pol: "22.15/16",
        mm: "582.61"
    },
    {
        pol: "23",
        mm: "584.2"
    },
    {
        pol: "23.1/32",
        mm: "584.99"
    },
    {
        pol: "23.1/16",
        mm: "585.78"
    },
    {
        pol: "23.3/32",
        mm: "586.58"
    },
    {
        pol: "23.1/8",
        mm: "587.37"
    },
    {
        pol: "23.5/32",
        mm: "588.17"
    },
    {
        pol: "23.3/16",
        mm: "588.96"
    },
    {
        pol: "23.7/32",
        mm: "589.75"
    },
    {
        pol: "23.1/4",
        mm: "590.55"
    },
    {
        pol: "23.9/32",
        mm: "591.34"
    },
    {
        pol: "23.5/16",
        mm: "592.14"
    },
    {
        pol: "23.11/32",
        mm: "592.93"
    },
    {
        pol: "23.3/8",
        mm: "593.72"
    },
    {
        pol: "23.7/16",
        mm: "595.31"
    },
    {
        pol: "23.1/2",
        mm: "596.9"
    },
    {
        pol: "23.9/16",
        mm: "598.48"
    },
    {
        pol: "23.5/8",
        mm: "600.07"
    },
    {
        pol: "23.11/16",
        mm: "601.66"
    },
    {
        pol: "23.3/4",
        mm: "603.25"
    },
    {
        pol: "23.13/16",
        mm: "604.84"
    },
    {
        pol: "23.7/8",
        mm: "606.42"
    },
    {
        pol: "23.15/16",
        mm: "608.01"
    }
]

function TabelaConversao () {

    function converter (e, tipo) {
        if (tipo === 1) {
            setPolegadas(e.target.value)
            const equalInMM = listaMedidas.find(p => p.pol === e.target.value).mm;
            setMilimetros(equalInMM)
        } else {
            setMilimetros(e.target.value)
            const equalInPol = listaMedidas.find(p => p.mm === e.target.value).pol;
            setPolegadas(equalInPol)
        }
    }

    const [conversao, setConversao] = useState()
    const [milimetros, setMilimetros] = useState(listaMedidas[0].mm)
    const [polegadas, setPolegadas] = useState(listaMedidas[0].pol)

    useEffect(()=> {
        setConversao(`${polegadas}" = ${milimetros}mm`)
    },[milimetros, polegadas])
    return (
        <div className='main'>
            <div className='txt-container'>
                <h2>Conversão de Medidas</h2>
                <p className='txt'><b>Precisão e eficiência</b> nos processos produtivos são fundamentais. Para isso, utilizar um conversor de medidas confiável e de fácil acesso permite que nossos parceiros sejam muito mais assertivos ao realizar os cálculos necessários para a definição dos produtos que a sua empresa precisa. Aproveite a nossa ferramenta e realize suas conversões com <b>agilidade e segurança</b>.</p>
            </div>
            <div className='table-container'>
                <div className='selects-container'>
                    <div className='grid'>
                        <div className='header-table'>
                            <h3>Polegadas (")</h3>
                        </div>
                        <select size="10" onChange={(e) => converter(e, 1) } value={ polegadas }>
                            { listaMedidas.map(item => <option key={item.pol}>{item.pol}</option>)}
                        </select>
                    </div>
                    <div className='grid'>
                        <div className='header-table'>
                            <h3>Milímetros (mm)</h3>
                        </div>
                        <select size="10" onChange={ (e) => converter(e, 2) } value={ milimetros }>
                            { listaMedidas.map(item => <option key={item.mm}>{item.mm}</option>)}
                        </select>
                    </div>
                </div>
    
                <div className='resultado'>
                   <span>{ conversao }</span>
                </div>
            </div>
            
        </div>
    )
}

export default TabelaConversao;