import './style.css';
import { useParams } from 'react-router-dom';
import Menu from '../../components/menu/Menu';
import { Square, Circle, Hexagon, Rectangle } from '@phosphor-icons/react';

const listaProdutos = [
    {
        nome: 'AÇO CARBONO',
        descricao: 'O aço carbono é um metal fabricado a partir dos elementos ferro e carbono. Nessa combinação, o carbono confere dureza e resistência e determina o grau em que tais propriedades físicas existem. Nesses metais a concentração de carbono é de até 2.5%, enquanto em outros aços essa concentração varia de 0.05% a 0.3%.',
        ligas: ['1020', '1045', '4140', '8620'],
        acabamento: ['Trefilados', 'Laminados'],
        aplicacoes: ['Equipamentos Agrícolas', 'Eletrodomésticos', 'Móveis', 'Componentes Automotivos', 'Parafusos', 'Estamparia', 'Engrenagens', 'Peças de Máquinas'],
        formatos: ['R', 'Q', 'S', 'C','TBR', 'TBRET', 'TBQ'],
        img: 'aco'
    },
    {
        nome: 'AÇO INOX',
        descricao: 'O aço inoxidável, também conhecido como aço inox, é uma liga metálica composta principalmente por ferro, cromo, níquel e carbono em diferentes proporções, dependendo da aplicação desejada. Devido à sua durabilidade, estética e resistência, o aço inoxidável é uma escolha popular em muitos setores industriais e é valorizado por sua capacidade de suportar ambientes agressivos e condições adversas, mantendo sua integridade estrutural e estética ao longo do tempo.',
        ligas: ['AISI 304', 'AISI 304L'],
        aplicacoes: ['Indústria Alimentícia', 'Indústria Química', 'Corrimãos', 'Equipamentos Cirúrgicos', 'Churrasqueiras', 'Componentes Automotivos', 'Coifas', 'Grelhas'],
        formatos: ['R', 'Q', 'S', 'C','TBR', 'TBRET', 'TBQ'],
        img: 'inox'
    },
    {
        nome: 'ALUMÍNIO',
        descricao: 'O alumínio é um metal leve e altamente maleável. Tornou-se crucial em diversas aplicações industriais devido às suas propriedades únicas. Ele é resistente à corrosão, possui alta condutividade elétrica e térmica, além de ser facilmente moldável. Essas características o tornam essencial na fabricação de uma ampla gama de produtos, desde embalagens de alimentos até componentes de aeronaves e estruturas arquitetônicas.',
        ligas: ['6351 T6', '6063 T5'],
        aplicacoes: ['Engenharia Estrutural', 'Construção Naval', 'Móveis', 'Componentes Automotivos', 'Fabricação de Equipamentos', 'Ornamentos', 'Acessórios para Cabos', 'Peças de Máquinas'],
        formatos: ['R', 'Q', 'S', 'C','TBR', 'TBRET', 'TBQ'],
        img: 'aluminio'
    },
    {
        nome: 'BRONZE',
        descricao: 'O bronze é uma liga metálica composta principalmente de cobre e estanho. É conhecido por suas propriedades mecânicas excepcionais, incluindo alta resistência à corrosão, excelente durabilidade e boa condutividade elétrica e térmica. Além disso, o bronze é bastante maleável e fácil de fundir, o que o torna adequado para uma variedade de processos de fabricação, como fundição, forjamento e usinagem.',
        ligas: ['TM 23', 'TM 620', 'SAE 65', 'SAE 68B', 'SAE 64', 'Grafitado'],
        aplicacoes: ['Engrenagens', 'Coroas', 'Buchas', 'Mancais', 'Instrumentos Musicais', 'Ornamentos', 'Componentes Automotivos', 'Componentes do Motores'],
        formatos: ['R', 'TBR'],
        img: 'bronze'
    },
    {
        nome: 'COBRE',
        descricao: 'O cobre é um metal macio e maleável, com excelente condutividade elétrica e térmica.  É um dos metais mais antigos a serem utilizados pela humanidade. O cobre é valorizado por sua alta qualidade e propriedades elétricas superiores, tornando-se uma escolha preferida em muitas aplicações onde a eficiência e a confiabilidade são fundamentais.',
        ligas: ['Eletrolítico'],
        aplicacoes: ['Aquecedores de Água', 'Componentes de Aparelhos Eletrônicos', 'Moedas', 'Componentes de Máquinas', 'Adornos', 'Utensílios Domésticos', 'Painéis Elétricos', 'Componentes Automotivos'],
        formatos: ['R', 'C','TBR'],
        img: 'cobre'
    },
    {
        nome: 'FERRO FUNDIDO',
        descricao: 'O ferro fundido é uma liga de ferro contendo entre 2% e 4% de carbono e, geralmente, outros elementos de liga. É produzido a partir de minérios de ferro em um alto-forno, onde o ferro é fundido e combinado com carbono e outros elementos de liga. O ferro fundido é conhecido por sua boa resistência à compressão, mas é menos dúctil e maleável do que o aço. Ele também tem boa resistência à corrosão e ao desgaste, tornando-o adequado para uma variedade de aplicações industriais.',
        ligas: ['Nodular'],
        aplicacoes: ['Blocos de Motores', 'Peças de Máquinas', 'Peças de Automóveis', 'Base para Móveis Industriais', 'Componentes de Maquinários Pesados', 'Implementos Agrícolas'],
        formatos: ['R'],
        img: 'ff'
    },
    {
        nome: 'LATÃO',
        descricao: 'O latão é uma liga metálica composta principalmente de cobre e zinco. O latão possui uma combinação única de propriedades mecânicas, elétricas e estéticas. Ele é mais resistente à corrosão do que o cobre puro e tem uma maior dureza e resistência à tração. Além disso, o latão é altamente maleável e pode ser facilmente moldado em uma variedade de formas por meio de processos como fundição, extrusão e laminação.',
        ligas: ['CLA 360'],
        aplicacoes: ['Parafusos', 'Porcas', 'Adornos', 'Jóias', 'Instrumentos Musicais', 'Encanamento', 'Componentes Automotivos', 'Conectores Elétricos'],
        formatos: ['R', 'Q', 'S', 'C'],
        img: 'latao'
    },
    {
        nome: 'NYLON',
        descricao: 'O Nylon é um polímero sintético amplamente utilizado em uma variedade de aplicações industriais e comerciais. As propriedades do Nylon incluem alta resistência mecânica, tenacidade, flexibilidade, resistência ao desgaste e à abrasão, além de boa resistência química e baixo coeficiente de atrito. Essas características tornam o Nylon ideal para uma ampla gama de usos, desde fibras têxteis até peças de engenharia de precisão.',
        ligas: ['Poliamida 6.0'],
        acabamento: ['Cor Branca', 'Cor Preta'],
        aplicacoes: ['Engrenagens', 'Rolamentos', 'Buchas', 'Peças de Máquinas', 'Carcaças de Componentes Eletrônicos', 'Revestimentos de Roldanas'],
        formatos: ['R', 'Q', 'C'],
        img: 'nylon'
    }
]

const icones = [
    { sigla: 'R', text: 'Redondo', icon: <Circle size={42} weight="fill"/> },
    { sigla: 'Q', text: 'Quadrado', icon: <Square size={42} weight="fill"/> },
    { sigla: 'S', text: 'Sextavado', icon: <Hexagon size={42} weight="fill"/> },
    { sigla: 'C', text: 'Barra Chata', icon: <Rectangle size={42} weight="fill"/> },
    { sigla: 'TBR', text: 'Tubo Redondo', icon: <Circle size={42}/> },
    { sigla: 'TBRET', text: 'Tubo Retangular', icon: <Rectangle size={42}/> },
    { sigla: 'TBQ', text: 'Tubo Quadrado', icon: <Square size={42}/> }
]

function SaibaMais () {
    const { nome } = useParams('nome')

    function renderiza () {
        const produto = listaProdutos.find(produto => produto.nome === nome)
        return (
            <div className='container'> 
                <div>
                    <img className="image-produto" src={require(`../../assets/${produto.img}.jpg`)}/>
                </div>
                <div className='content-container'>
                    <h3>
                        { produto.nome } 
                    </h3>
                    <hr/>
                    <p>
                        { produto.descricao }
                    </p>
                    <div className='section-title-container'>
                        <h3>Aplicações</h3>
                        <hr/>
                    </div>
                    <div>
                        <ul className='list'>
                            {
                                produto.aplicacoes.map(item => <li key={item}>{item}</li>) 
                            }
                        </ul>
                    </div>
                    <div className='section-title-container'>
                        <h3>Ligas Disponíveis</h3>
                        <hr/>
                    </div>
                    <div>
                        <ul className='list'>
                            {
                                produto.ligas.map(item => <li key={item}>{item}</li>) 
                            }
                        </ul>
                    </div>
                    { 
                    produto.acabamento?.length > 0 && 
                    <div>
                        <div className='section-title-container'>
                            <h3>Acabamentos</h3>
                            <hr/>
                        </div>
                        <div>
                            <ul className='list'>
                                {
                                    produto.acabamento.map(item => <li key={item}>{item}</li>) 
                                }
                            </ul>
                        </div>
                    </div>
                    }
                    
                    <div className='section-title-container'>
                        <h3>Formatos Disponíveis</h3>
                        <hr/>
                    </div>
                    <div>
                        <ul className='section-formatos'>
                            {
                                icones.filter(item => produto.formatos.find(sigla => sigla === item.sigla)).map(item => <div className="formato" key={item.sigla}>{item.icon}{item.text}</div>)
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <Menu typeRedirect={2}></Menu>
            { 
                renderiza()
            }
        </div>
    )
}

export default SaibaMais;