import { Component, React } from 'react';
import './style.css';
import { Certificate, Ruler, ClockClockwise } from "@phosphor-icons/react";

const iconComponents = {
    Certificate: <Certificate size={90}/>,
    Ruler: <Ruler size={90}/>,
    ClockClockwise: <ClockClockwise size={90}/>
}

class CardWhy extends Component {

    render () {
        
        return (
            <div className='card-why'>
                {iconComponents[this.props.icon]}
                <h3>
                    {this.props.title}
                </h3>
                <span>
                    {this.props.text}
                </span>
            </div>
        )
    }
}
export default CardWhy;