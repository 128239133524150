import React, { useState, useEffect } from 'react';
import './style.css';
function Menu (props) {

    const [windowWidth, setWindowWidth] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [menuClass, setMenuClass] = useState('hidden');
    const [iconeHamburguerClass, setIconeHamburguerClass] = useState('barra-menu-hamburguer unclicked')

    function toggleMenu () {
        setOpenMenu(!openMenu)
    }

    useEffect(() => {
        if (openMenu) {
            setMenuClass('menu-mobile-active')
            setIconeHamburguerClass('barra-menu-hamburguer clicked')
        } else {
            setMenuClass('hidden')
            setIconeHamburguerClass('barra-menu-hamburguer unclicked')
        }
    }, [openMenu])
    useEffect(() => {
        setWindowWidth(window.innerWidth)
    }, [])
    return (
        <div>
            
            {
                windowWidth <= 480 ?
                <div>
                    <div className='header-menu-mobile'>
                        <img src={require("../../assets/LogoHorizontalInox.png")} alt="Logo Butzke Metais" className='logo-mobile'/>
                        <nav className='nav-menu-hamburguer'>
                            <div className='menu-hamburguer' onClick={toggleMenu}>        
                                <div className={iconeHamburguerClass}></div>
                                <div className={iconeHamburguerClass}></div>
                                <div className={iconeHamburguerClass}></div>
                            </div>
                        </nav>
                    </div>
                    <div className={menuClass}>
                        <ul className='lista-itens-menu-mobile'>
                            <div onClick={toggleMenu}>
                                <li className='itens-menu-mobile'><a href={ props.typeRedirect === 1 ? '#header' : '/#header' } >HOME</a></li>
                                <hr className='separador-menu-mobile'/>
                            </div>
                            <div onClick={toggleMenu}>
                                <li className='itens-menu-mobile'><a href={ props.typeRedirect === 1 ? '#produtos-container' : '/#produtos-container'}>PRODUTOS</a></li>
                                <hr/>
                            </div>
                            <div onClick={toggleMenu}>
                                <li className='itens-menu-mobile'><a href={ props.typeRedirect === 1 ? "#peso-container" : '/#peso-container'}>PESO TEÓRICO</a></li>
                                <hr/> 
                            </div>
                            <div onClick={toggleMenu}>
                                <li className='itens-menu-mobile'><a href={ props.typeRedirect === 1 ? "#contato-container" : '/#contato-container' }>CONTATO</a></li>
                                <hr/>
                            </div>
                            
                            
                            
                        </ul>
                    </div>
                </div>
                
                :
                <div className='menu-container'>
                    <img src={require("../../assets/LogoHorizontalInox.png")} alt="Logo Butzke Metais" className='logo'/>
                    <nav>
                        <ul>
                            <li className='itens-menu'><a href={ props.typeRedirect === 1 ? '#header' : '/#header' } >HOME</a></li>
                            <li className='itens-menu'><a href={ props.typeRedirect === 1 ? '#produtos-container' : '/#produtos-container'}>PRODUTOS</a></li>
                            <li className='itens-menu'><a href={ props.typeRedirect === 1 ? "#peso-container" : '/#peso-container'}>PESO TEÓRICO</a></li>
                            <li className='itens-menu'><a href={ props.typeRedirect === 1 ? "#contato-container" : '/#contato-container' }>CONTATO</a></li>
                        </ul>
                    </nav>
                </div>
            }
            
        </div>  
    )
}

export default Menu;