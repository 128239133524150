import { React, useEffect, useState } from 'react';
import './style.css';
import { Square, Circle, Hexagon, Rectangle } from '@phosphor-icons/react';

const listaMateriais = [
    { 
        name: 'AÇO CARBONO', 
        formatos: [ 
            { txt: 'Barras Retangulares', icon: <Rectangle size={28} weight="fill" /> }, 
            { txt: 'Barras Redondas', icon: <Circle size={28} weight="fill" /> }, 
            { txt: 'Barras Quadradas', icon: <Square size={28} weight="fill" /> }, 
            { txt: 'Barras Sextavadas', icon: <Hexagon size={28} weight="fill"/> }, 
            { txt: 'Tubos Redondos', icon: <Circle size={28}/>}, 
            { txt: 'Tubos Quadrados', icon: <Square size={28}/>}, 
            { txt: 'Tubos Retangulares', icon: <Rectangle size={28}/> }
        ]
    },
    { 
        name: 'AÇO INOX', 
        formatos: [ 
            { txt: 'Barras Retangulares', icon: <Rectangle size={28} weight="fill" /> }, 
            { txt: 'Barras Redondas', icon: <Circle size={28} weight="fill" /> }, 
            { txt: 'Barras Quadradas', icon: <Square size={28} weight="fill" /> }, 
            { txt: 'Barras Sextavadas', icon: <Hexagon size={28} weight="fill"/> }, 
            { txt: 'Tubos Redondos', icon: <Circle size={28}/>}, 
            { txt: 'Tubos Quadrados', icon: <Square size={28}/>}, 
            { txt: 'Tubos Retangulares', icon: <Rectangle size={28}/>}
        ]
    },
    { 
        name: 'ALUMÍNIO', 
        formatos: [ 
            { txt: 'Barras Retangulares', icon: <Rectangle size={28} weight="fill" /> }, 
            { txt: 'Barras Redondas', icon: <Circle size={28} weight="fill" /> }, 
            
            { txt: 'Barras Sextavadas', icon: <Hexagon size={28} weight="fill"/> }, 
            { txt: 'Tubos Redondos', icon: <Circle size={28}/>}, 
            { txt: 'Tubos Quadrados', icon: <Square size={28}/>}, 
            { txt: 'Tubos Retangulares', icon: <Rectangle size={28}/>}
        ]
    },
    { 
        name: 'BRONZE', 
        formatos: [
            { txt: 'Tarugos', icon: <Circle size={28} weight='fill'/> }, 
            { txt:'Buchas', icon: <Circle size={28}/> }
        ] 
    },
    { 
        name: 'COBRE',
        formatos: [ 
            { txt: 'Barras Retangulares', icon: <Rectangle size={28} weight="fill" /> }, 
            { txt: 'Barras Redondas', icon: <Circle size={28} weight="fill" /> }, 
            { txt: 'Barras Quadradas', icon: <Square size={28} weight="fill" /> }, 
            { txt: 'Barras Sextavadas', icon: <Hexagon size={28} weight="fill"/> }
        ]
    },
    { 
        name: 'FERRO FUNDIDO', 
        formatos: [
            { txt: 'Barras Redondas', icon: <Circle size={28} weight='fill'/> }
        ]
    },
    { 
        name: 'LATÃO', 
        formatos: [ 
            { txt: 'Barras Retangulares', icon: <Rectangle size={28} weight="fill" /> }, 
            { txt: 'Barras Redondas', icon: <Circle size={28} weight="fill" /> }, 
            { txt: 'Barras Quadradas', icon: <Square size={28} weight="fill" /> }, 
            { txt: 'Barras Sextavadas', icon: <Hexagon size={28} weight="fill"/> }
        ]
    },
    { 
        name: 'NYLON', 
        formatos: [ 
            { txt: 'Barras Retangulares', icon: <Rectangle size={28} weight="fill" /> }, 
            { txt: 'Barras Redondas', icon: <Circle size={28} weight="fill" /> }, 
            { txt: 'Barras Quadradas', icon: <Square size={28} weight="fill" /> }, 
            { txt: 'Barras Sextavadas', icon: <Hexagon size={28} weight="fill"/> }, 
            { txt: 'Buchas', icon: <Circle size={28}/> }
        ]
    }
]

const dadosProdutos = [
    { name: 'Barras Retangulares', img: <img src={require('../../assets/calculadora/calc-retangulo.jpg')} alt="Barras Retangulares"/>, calc: ['L', 'E'], pesoTeorico: [{ name: 'Aço Carbono', }]},
    { name: 'Barras Redondas', img: <img src={require('../../assets/calculadora/calc-redondo.jpg')} alt="Barras Redondas"/>, calc: ['D'] },
    { name: 'Barras Quadradas', img: <img src={require('../../assets/calculadora/calc-quadrado.jpg')} alt="Barras Quadradas"/>, calc: ['A']},
    { name: 'Barras Sextavadas', img: <img src={require('../../assets/calculadora/calc-sextavado.jpg')} alt="Barras Sextavadas"/>, calc: ['A']},
    { name: 'Tarugos', img: <img src={require('../../assets/calculadora/calc-redondo.jpg')} alt="Tarugos"/>, calc: ['D'] },
    { name: 'Buchas', img: <img src={require('../../assets/calculadora/calc-bucha.jpg')} alt="Buchas"/>, calc: ['De', 'Di']},
    { name: 'Cantoneiras', img: <img src={require('../../assets/calculadora/calc-cantoneira.jpg')} alt="Cantoneiras"/>, calc: ['L', 'E'] },
    { name: 'Tubos Quadrados', img: <img src={require('../../assets/calculadora/calc-tubo-quadrado.jpg')} alt="Tubo Quadrados"/>, calc: ['L', 'E'] },
    { name: 'Tubos Redondos', img: <img src={require('../../assets/calculadora/calc-tubo-redondo.jpg')} alt="Tubo Redondos"/>, calc: ['D', 'E'] },
    { name: 'Tubos Retangulares', img: <img src={require('../../assets/calculadora/calc-tubo-retangulo.jpg')} alt="Tubo Retangulares"/>, calc: ['L1', 'L2', 'E'] },
    { name: 'Chapas', img: <img src={require('../../assets/calculadora/calc-chapa.jpg')} alt="Chapas"/>, calc: ['L', 'E'] }
]

const tabelaPesoEspecifico = [
    { name: 'Aço Carbono', peso: 7.85 },
    { name: 'Aço Inox', peso: 7.85 },
    { name: 'Alumínio', peso: 2.71 },
    { name: 'Bronze', peso: 8.86 },
    { name: 'Cobre', peso: 8.93 },
    { name: 'Ferro Fundido', peso: 7.2 },
    { name: 'Latão', peso: 8.70 },
    { name: 'Nylon', peso: 1.14 }
]

function CalculadoraPeso () {
    
    function selectMetal(metal) {
        setSelectedMetal(metal)

        const Formatos = listaMateriais.find((material) => material.name === metal).formatos;
        setSelectedFormatos(Formatos)

        var elementos = document.getElementsByClassName('materiais-btn')
        for(var i = 0; i < elementos.length; i++) {
            elementos[i].classList.remove('materiais-btn-active')
        }

        var elemento = document.getElementsByName(metal);
        elemento[0].classList.add('materiais-btn-active');
        handleFormato(Formatos[0].txt)
    }

    function handleFormato (formato) {
        setSelectedFormato(formato)

        var elementos = document.getElementsByClassName('formatos-items')
        for(var i = 0; i < elementos.length; i++) {
            elementos[i].classList.remove('formatos-items-active')
        }
        setTimeout (() => {

            var elemento = document.getElementsByName(formato);
            elemento[0].classList.add('formatos-items-active');
        }, 10)

        clearCampos()
    }

    function clearCampos () {
        setAltura('')
        setLado('')
        setDiametro('')
        setEspessura('')
        setLado1('')
        setLado2('')
        setDiametroExterno('')
        setDiametroInterno('')
        setComprimento('')
        setResultado('')
    }

    function setValores (event, input) {
        switch (input) {
            case 'A': 
                setAltura(event.target.value)
                break
            case 'C':
                setComprimento(event.target.value)
                break
            case 'L':
                setLado(event.target.value)
                break
            case 'D':
                setDiametro(event.target.value)
                break
            case 'E':
                setEspessura(event.target.value)
                break
            case 'De':
                setDiametroExterno(event.target.value)
                break
            case 'Di':
                setDiametroInterno(event.target.value)
                break
            case 'L1':
                setLado1(event.target.value)
                break
            case 'L2':
                setLado2(event.target.value)
                break
            default:
                break
        }
    }
    function calculoPesoTeorico () {
        var pesoEspecifico = tabelaPesoEspecifico.find(metal => metal.name.toLowerCase() === selectedMetal.toLowerCase()).peso
        switch(selectedFormato) {
            case 'Barras Quadradas':
                if (altura && comprimento){
                    const pesoTeorico = (((((parseFloat(altura) * parseFloat(altura)) * pesoEspecifico) / 1000) / 1000) * parseFloat(comprimento)).toFixed(3)
                    setResultado(pesoTeorico + 'kg')
                }else {
                    setResultado('')
                }
                break
            case 'Tarugos':
            case 'Barras Redondas':
                if (diametro && comprimento) {
                    var diametroPeca = selectedMetal === 'BRONZE' ? parseFloat(diametro) + 1.58 : parseFloat(diametro)
                    const pesoTeorico = (diametroPeca * diametroPeca * 3.1416 * pesoEspecifico / 1000 / 4000 * parseFloat(comprimento)).toFixed(3)
                    setResultado(pesoTeorico + 'kg')
                } else {
                    setResultado('')
                }
                break
            case 'Barras Retangulares':
                if (lado && espessura && comprimento){
                    const pesoTeorico = (parseFloat(lado) * parseFloat(espessura) * pesoEspecifico / 1000 / 1000 * parseFloat(comprimento)).toFixed(3)
                    setResultado(pesoTeorico + 'kg')
                } else {
                    setResultado('')
                }
                break
            case 'Barras Sextavadas':
                if (altura && comprimento){
                    const pesoTeorico = (parseFloat(altura) * parseFloat(altura) * 3 * pesoEspecifico / 1000 / 3460 * parseFloat(comprimento)).toFixed(3)
                    setResultado(pesoTeorico + 'kg')
                } else {
                    setResultado('')
                }
                break
            case 'Buchas':
                if (diametroExterno && diametroInterno && comprimento) {
                    const dE = parseFloat(diametroExterno) + 1.58
                    const dI = parseFloat(diametroInterno) - 1.58
                    const pesoTeorico = (((dE * dE) - (dI * dI)) * 3.1416 * pesoEspecifico / 1000 / 4000 * parseFloat(comprimento)).toFixed(3)
                    setResultado(pesoTeorico + 'kg')
                } else {
                    setResultado('')
                }
                break
            case 'Tubos Quadrados':
                if (lado && espessura && comprimento) {
                    const pesoTeorico = ( ((parseFloat(lado) * parseFloat(lado)) - (parseFloat(lado) - (parseFloat(espessura) * 2)) * (parseFloat(lado) - (parseFloat(espessura) * 2))) * pesoEspecifico / 1000 / 1000 * parseFloat(comprimento) ).toFixed(3)
                    setResultado(pesoTeorico + 'kg')
                } else {
                    setResultado('')
                }
                break
            case 'Tubos Redondos':
                if (diametro && espessura && comprimento) {
                    const pesoTeorico = ( ((parseFloat(diametro) * parseFloat(diametro)) - (parseFloat(diametro) - (parseFloat(espessura) * 2)) * (parseFloat(diametro) - (parseFloat(espessura) * 2))) * 3.1416 * pesoEspecifico / 1000 / 4000 * parseFloat(comprimento) ).toFixed(3)
                    setResultado(pesoTeorico + 'kg')

                } else {
                    setResultado('')
                }
                break
            case 'Tubos Retangulares':
                if (lado1 && lado2 && espessura && comprimento) {
                    const pesoTeorico = ( ((lado1 * lado2) - ((lado1 - 2 * espessura) * (lado2 - 2 * espessura))) * pesoEspecifico / 1000 / 1000 * comprimento ).toFixed(3)
                    setResultado(pesoTeorico + 'kg')
                } else {
                    setResultado('')
                }
                break
            default:
                break
        }
    }

    const [selectedMetal, setSelectedMetal] = useState(listaMateriais[0].name);
    const [selectedFormato, setSelectedFormato] = useState('Barras Retangulares');
    const [selectedFormatos, setSelectedFormatos]  = useState(listaMateriais[0].formatos);

    const [altura, setAltura] = useState('');
    const [lado, setLado] = useState('');
    const [diametro, setDiametro] = useState('');
    const [espessura, setEspessura] = useState('');
    const [lado1, setLado1] = useState('');
    const [lado2, setLado2] = useState('');
    const [diametroExterno, setDiametroExterno] = useState('');
    const [diametroInterno, setDiametroInterno] = useState('');
    const [comprimento, setComprimento] = useState('');
    const [resultado, setResultado] = useState('');
    
    useEffect (() => {
        var material = document.getElementsByName(selectedMetal);
        material[0].classList.add('materiais-btn-active');
        var formato = document.getElementsByName(selectedFormato);
        formato[0].classList.add('formatos-items-active');
    },[])

    useEffect (() => {
        calculoPesoTeorico()
    }, [altura, lado, diametro, espessura, lado1, lado2, diametroExterno, diametroInterno, comprimento])

        return (
            <div>   
                <div className='materiais'>
                        { listaMateriais.map((item) => {
                            return (
                                <div key={item.name} name={item.name} onClick={() => selectMetal(item.name)} className="materiais-btn">
                                    { item.name }
                                </div>
                            )
                        }) }
                </div>
                <div className='calc'>
                    <div className='formatos-grid'>
                        { 
                            selectedFormatos.map((item) => {
                                return (
                                    <div key={item.txt} name={item.txt} className="formatos-items" onClick={() => handleFormato(item.txt)}>
                                        <span>{item.icon}</span>
                                        <p>{item.txt}</p>
                                    </div>
                                )
                            }) 
                        }
                    </div>
                    <div className='container-calc'>
                        <div className='image-metais-calculadora'>
                            {dadosProdutos.find(item => item.name === selectedFormato).img}
                        </div>
                        <div>
                            <form id="calc-form">
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('A') && 
                                    <div>
                                        <p><span>A</span> - Altura (mm)</p>
                                        <input type="number" placeholder='-' value={altura} onChange={(event) => setValores(event, 'A')}/> 
                                    </div>
                                }
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('D') &&
                                    <div>
                                        <p><span>D</span> - Diâmetro (mm)</p>
                                        <input type="number" placeholder='-' value={diametro} onChange={(event) => setValores(event, 'D')}/> 
                                    </div>
                                }
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('L') &&
                                    <div>
                                        <p><span>L</span> - Lado (mm)</p>
                                        <input type="number" placeholder='-' value={lado} onChange={(event) => setValores(event, 'L')}/> 
                                    </div>
                                }
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('E') && 
                                    <div>
                                        <p><span>E</span> - Espessura (mm)</p>
                                        <input type="number" placeholder='-' value={espessura} onChange={(event) => setValores(event, 'E')}/> 
                                    </div>
                                }
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('L1') &&
                                    <div>
                                        <p><span>L1</span> - Lado 1 (mm)</p>
                                        <input type="number" placeholder='-' value={lado1} onChange={(event) => setValores(event, 'L1')}/> 
                                    </div>
                                }
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('L2') &&
                                    <div>
                                        <p><span>L2</span> - Lado 2 (mm)</p>
                                        <input type="number" placeholder='-' value={lado2} onChange={(event) => setValores(event, 'L2')}/> 
                                    </div>
                                }
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('De') &&
                                    <div>
                                        <p><span>De</span> - Diâmetro Externo (mm)</p>
                                        <input type="number" placeholder='-' value={diametroExterno} onChange={(event) => setValores(event, 'De')}/> 
                                    </div>
                                }
                                {
                                    dadosProdutos.find(item => item.name === selectedFormato).calc.includes('Di') &&
                                    <div>
                                        <p><span>Di</span> - Diâmetro Interno (mm)</p>
                                        <input type="number" placeholder='-' value={diametroInterno} onChange={(event) => setValores(event, 'Di')}/> 
                                    </div>
                                }
                                <div>
                                    <p><span>C</span> - Comprimento (mm)</p>
                                    <input type="number" placeholder='-' value={comprimento} onChange={(event) => setValores(event, 'C')}/> 
                                </div>
                                <div>
                                    <p><span>Peso por Peça</span></p>
                                    <input placeholder='-' value={resultado} readOnly/> 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="alerta">
                    <h3>ATENÇÃO:</h3><span>Os pesos apresentados possuem apenas caráter informativo. As variações apresentadas são calculadas de acordo com as tolerâncias especificadas pelos fabricantes.</span>
                </div>
            </div>
        )
    }

export default CalculadoraPeso