import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import SaibaMais from './pages/SaibaMais';

function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/produto/:nome" element={<SaibaMais/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp;