import RoutesApp from "./routes";

function App () {
    return (
        <div>
            <RoutesApp/>
        </div>
    )
}

export default App;